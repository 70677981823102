<template>
  <div class="content">{{templates}}
    <table class="table">
      <thead class="table__header">
      <tr class="table__row">
        <th class="table__header-cell -first">
          <div class="table__header-cell-content">Templates</div>
        </th>
        <th class="table__header-cell -second">
          <div class="table__header-cell-content">Bearbeitet am</div>
        </th>
        <th class="table__header-cell -last">
          <div class="table__header-cell-content">Aktionen</div>
        </th>
      </tr>
      </thead>
      <tbody class="table__body">
        <tr class="table__row" v-for="(template, index) in templates" v-bind:key="index">
          <td class="table__cell -first">
            <div class="table__cell-content -first">
              {{  template.title }}
            </div>
          </td>
          <td class="table__cell -second">
            <div class="table__cell-content -second">
              XX.XX.XXXX
            </div>
          </td>
          <td class="table__cell -last">
            <div class="table__cell-content -last">
              <button class="button edit-button" @click="uploadTemplate(template.id)">
                <Icon name="upload" css-class="-edit" />
              </button>
              <button class="button edit-button" @click="downloadTemplate(template.id, template.title)">
                <Icon name="download" css-class="-edit" />
              </button>
              <button class="button edit-button" @click="deleteTemplate(template.id)">
                <Icon name="delete-button" css-class="-edit" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Icon from "@/components/generic/Icon";

export default {
  name: 'Templates',
  components: {
    Icon,
  },
  props: {
    dataHandler: {
      type: Object,
    },
    customerId: {
      type: String,
    },
    routerData: {
      type: Array,
    },
  },
  data() {
    return {
      templates: [],
    };
  },
  computed: {
  },
  methods: {
    async getTemplates() {
      this.templates = await this.dataHandler.getTemplates();
    },
    async downloadTemplate(templateId, templateTitle) {
      let a = document.createElement("a");
      let decodedId = decodeURI(templateId)
      let templateObject = await this.dataHandler.getTemplate(decodedId);
      let templateObjectJson = JSON.stringify(templateObject)
      let file = new Blob([templateObjectJson], {type: 'application/json'});
      a.href = URL.createObjectURL(file);
      a.download = templateTitle + ".json";
      a.click();
    },
  },
  mounted() {
    this.getTemplates()
  }
}
</script>

<style lang="scss" scoped>

.table {
  &__body {
    max-height: 75vh;
  }

  &__header-cell {
    &.-first {
      flex-grow: 1;
    }

    &.-second,
    &.-last {
      max-width: rem(140px);
    }
  }

  &__cell {
    &.-first {
      flex-grow: 1;
    }

    &.-second,
    &.-last {
      max-width: rem(140px);
    }
  }

  &__cell-content {
    &.-first {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.-last {
      justify-content: center;
    }
  }
}

</style>